body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-div {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.input-group {
  display: grid;
  color: #333;
  float: left;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 20px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 2px;
}

input {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 13px;
  height: 33px;
  margin: 0;
  padding: 0 0 0 15px;
  width: 100%;
}

select {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 13px;
  height: 33px;
  margin: 0;
  padding: 0 0 0 15px;
  width: 100%;
}

.error-message {
  color: #cc0033;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
}

.input-group .error-message {
  display: none;
}

/* Error Styling */

.error label {
  color: #cc0033;
}

.error input,
.error select {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

.error .error-message {
  display: inline-block;
}

label {
  float: left;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 70px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.form-records-list{
  margin-top:40px;
  margin-bottom: -60px;

}

.btn-create{
  width: 200px;
}
.creation-head{
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #253b56;

}
.records-admin-search{
  width: 80%;
  float: inherit;
  margin: 40px auto;
}
.records-admin-labels{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.records-admin-labels label{
display: flex;
align-items: center;
width: 190px;
justify-content: flex-start;
}

.records-admin-labels label input{
  width: 40px;
  }

.records-admin-btns{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 40px;
}

.records-admin-btns .btn-search,
.records-admin-btns .btn-clear{
  width: 200px;
}

/* The Modal (background) */
.modal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.hide {
  display: none;
}

.show {
  display: "block";
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.red-button {
  background: red;
  color: white;
  padding: 1px 10px;
}
